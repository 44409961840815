import React, { Fragment, Component } from "react";
import { Redirect } from "react-router-dom";

export default class SSOLoginHandler extends Component {
  state = { globalSet: false };
  componentDidMount = async () => {
    if (!this.state.status) {
      try {
        let ssoResponse = JSON.parse(
          decodeURIComponent(this.props.match.params.ssoResponse)
        );

        if (typeof ssoResponse !== "object") {
          this.setState({ status: "error" });
        } else {
          this.setState({
            status: "processed",
            token: ssoResponse.token,
            ssoRequest: ssoResponse.request,
          });
          const user = this.props.user;
          if (typeof user === "undefined") {
            await this.props.setToken(ssoResponse.token);
          } else if (ssoResponse.token !== user.token) {
            await this.props.setToken(ssoResponse.token);
          } else {
            this.setState({ status: "redirect", uri: "/" });
          }
        }
      } catch (error) {
        console.log({ error });
        this.setState({ status: "error" });
      }
    }
  };
  render() {
    if (!this.state.status) {
      return (
        <Fragment>
          <div className="innerContainerAutoSize">
            <h2 className="text-center">Processing token.</h2>
          </div>
        </Fragment>
      );
    } else if (this.state.status === "redirect") {
      return <Redirect to={this.state.uri} />;
    } else if (this.state.status === "error") {
      return (
        <Fragment>
          <div className="innerContainerAutoSize">
            <h2 className="text-center">
              An error has occured. Please close your browser and retry.
            </h2>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="innerContainerAutoSize">
            <h2 className="text-center">
              An error has occured. Please close your browser and retry.
            </h2>
          </div>
        </Fragment>
      );
    }
  }
}
