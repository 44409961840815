import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Nav extends Component {
  handleLogout = async () => {
    await this.props.initSSOLogout();
  };
  reloadHome = () => {
    window.location = "/";
  };
  render() {
    let authButtons;
    if (this.props.user) {
      authButtons = (
        <React.Fragment>
          <li className="nav-item">
            <Link
              to={"#"}
              onClick={this.handleLogout}
              className="nav-link nav-item-lite"
              id="navBar-logout"
            >
              Log Out
            </Link>
          </li>
        </React.Fragment>
      );
    } else {
      authButtons = (
        <React.Fragment>
          <li className="nav-item">
            <Link
              className="nav-item"
              to={"/ssoLoginReferrer"}
              id="navBar-login"
              className="nav-item-lite"
            >
              Login
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-item"
              to={"/ssoRegister"}
              id="navBar-register"
              className="nav-item-lite"
            >
              Sign Up
            </Link>
          </li>
        </React.Fragment>
      );
    }
    return (
      <nav>
        <ul className="navbar">
          <li className="nav-logo" onClick={this.reloadHome}>
            SCENCDN
          </li>
          <li className="nav-item">
            <Link to={"/"} id="navBar-home" className="nav-item-bold">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/contact"} id="navBar-contact" className="nav-item-bold">
              Contact
            </Link>
          </li>
          {authButtons}
        </ul>
      </nav>
    );
  }
}
