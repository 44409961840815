import Endpoints from "../endpoints.json";
import Axios from "axios";
import { name } from "../../package.json";
const loginPath = "/ssoLoginHandler";
const idpLoginPath = "/ssoLoginRequest/";
const logoutPath = "/ssoLogoutHandler";
const idpLogoutPath = "/ssoLogoutRequest/";
const referringApp = name;

const get = async (endpointName, user) => {
  try {
    Axios.defaults.headers.common["Authorization"] = "Bearer: " + user.token;
    const res = await Axios.get(Endpoints[endpointName].GET);
    return res.data;
  } catch (error) {
    return { error: true, errorMessage: error };
  }
};

const post = async (endpointName, user, body) => {
  try {
    Axios.defaults.headers.common["Authorization"] = "Bearer: " + user.token;
    const res = await Axios.post(Endpoints[endpointName].POST, body);
    return res.data;
  } catch (error) {
    console.log({ error });
    if (typeof error?.response?.data?.errorMessage === "string") {
      return { error: true, errorMessage: error.response.data.errorMessage };
    }
    return { error: true, errorMessage: error.errorMessage };
  }
};
const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
const isoDate = (time, tz) => {
  return time;
};
const getDomainName = (hostName) => {
  return hostName.substring(
    hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1
  );
};

const idpURL = async (dest) => {
  const versionResp = await Axios.get(Endpoints.version.GET);
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  let redirURL;
  let path;
  const logoutURL = url.origin + logoutPath;
  if (dest === "login") {
    const returnURL = url.origin + loginPath;
    const ssoLoginRequest = encodeURIComponent(
      JSON.stringify({ referringApp, returnURL, logoutURL })
    );
    path = idpLoginPath + ssoLoginRequest;
  }
  if (dest === "logout") {
    path = idpLogoutPath;
  }
  if (dest === "logoutInit") {
    const returnURL = url.origin + "/";
    const ssoLogoutRequest = encodeURIComponent(
      JSON.stringify({ referringApp, returnURL })
    );
    path = idpLogoutPath + ssoLogoutRequest;
  }

  const version = versionResp.data;
  if (typeof version === "object") {
    if (version.stage === "pr") {
      redirURL =
        url.protocol + "//identity." + getDomainName(url.hostname) + path;
    } else if (version.isAWS === false) {
      redirURL = url.protocol + "//" + url.hostname + ":3000" + path;
    } else {
      redirURL =
        url.protocol +
        "//identity" +
        "-" +
        version.stage +
        "-" +
        version.region +
        "." +
        getDomainName(url.hostname) +
        path;
    }
    return redirURL;
  }
};
const appURL = async (app, uri) => {
  if (!uri.startsWith("/")) {
    uri = "/" + uri;
  }
  const versionResp = await Axios.get(Endpoints.version.GET);
  const version = versionResp.data;
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  let redirURL;
  let prefix;
  let devPort;
  let host = getDomainName(url.hostname);
  switch (app) {
    case "identity":
      prefix = "";
      devPort = 3001;
      host = "wal-sys.com";
      break;
    case "sampleApp":
      prefix = "";
      devPort = 3001;
      host = "wal-sys.com";
      break;
    case "billing":
      prefix = "";
      devPort = 3002;
      host = "wal-sys.com";
      break;
    case "journalApp":
      prefix = "journalapp";
      devPort = 3003;
      host = "wal-sys.com";
      break;
    case "sprout":
      prefix = "";
      devPort = 3004;
      host = "sprout.financial";
      break;
    case "sproutApp":
      prefix = "app";
      devPort = 3005;
      host = "sprout.financial";
      break;
    case "telco":
      prefix = "";
      devPort = 3006;
      host = "wal-sys.com";
      break;
    case "admin":
      prefix = "";
      devPort = 3007;
      host = "wal-sys.com";
      break;
    default:
      console.log({ error: true, errorMessage: "invalid app" });
      return "#";
  }
  if (typeof version === "object") {
    if (version.stage === "pr") {
      //Production
      //PR
      if (prefix.length > 0) {
        redirURL = url.protocol + "//" + prefix + "." + host + uri;
      } else {
        redirURL = url.protocol + "//" + host + uri;
      }
    } else if (version.isAWS === false) {
      //Local
      //Local
      redirURL = url.protocol + "//" + url.hostname + ":" + devPort + uri;
    } else {
      //Default non-prod
      redirURL =
        url.protocol +
        "//" +
        prefix +
        "-" +
        version.stage +
        "-" +
        version.region +
        "." +
        host +
        uri;
    }
    return redirURL;
  } else {
    console.log({
      error: true,
      errorMessage: "invalid version info retrieved.",
    });
    return "#";
  }
};
const websocketURL = (token) => {
  if (Endpoints.meta.syncType === "local") {
    return `ws://${Endpoints.meta.localWSHost}:${Endpoints.meta.localWSPort}/${Endpoints.meta.stage}?token=Bearer ${token}`;
  } else {
    return `wss://${Endpoints.meta.awsWSHost}/${Endpoints.meta.pathPrefix}_${Endpoints.meta.stage}?token=Bearer ${token}`;
  }
};
export {
  get,
  post,
  sleep,
  isoDate,
  getDomainName,
  idpURL,
  appURL,
  websocketURL,
};
