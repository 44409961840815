import React, { Component } from "react";
import { Fragment } from "react";
import { name } from "../../../package.json";
import Axios from "axios";
import Endpoints from "../../endpoints.json";

const loginPath = "/ssoLoginHandler";
const idpPath = "/register/";
const referringApp = name;

const getDomainName = (hostName) => {
  return hostName.substring(
    hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1
  );
};

export default class Register extends Component {
  componentDidMount() {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    Axios.get(Endpoints.version.GET)
      .then((data) => {
        const version = data.data;
        console.log({ version });
        const returnURL = url.origin + loginPath;
        const ssoLoginRequest = encodeURIComponent(
          JSON.stringify({ referringApp, returnURL })
        );
        console.log({ version, ssoLoginRequest, url });

        let redirURL;
        if (typeof version === "object") {
          if (version.stage === "pr") {
            redirURL =
              url.protocol +
              "//identity." +
              getDomainName(url.hostname) +
              idpPath +
              ssoLoginRequest;
          } else if (version.isAWS === false) {
            redirURL =
              url.protocol +
              "//" +
              url.hostname +
              ":3000" +
              idpPath +
              ssoLoginRequest;
          } else {
            redirURL =
              url.protocol +
              "//identity" +
              "-" +
              version.stage +
              "-" +
              version.region +
              "." +
              getDomainName(url.hostname) +
              idpPath +
              ssoLoginRequest;
          }
          console.log({ redirURL });
          location = redirURL;
        }
      })
      .catch(console.log);
  }
  render() {
    return (
      <Fragment>
        {" "}
        <div className="innerContainerAutoSize">
          <h4>Redirect to register.</h4>
          <img
            src="/images/spinner/spin1.gif"
            width="50"
            height="50"
            className="center"
          />
        </div>
      </Fragment>
    );
  }
}
