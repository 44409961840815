import React, { Component } from "react";
import Axios from "axios";
import Endpoints from "../../endpoints.json";
import { capAll } from "../../lib/contentManipulation";

export default class Footer extends Component {
  state = {};
  componentDidMount() {
    //localStorage.getItem('token');
    if (this.props.user) {
      Axios.get(Endpoints.serverInfo.GET)
        .then((data) => {
          console.log(data);
          this.setState({
            version: data.data,
          });
        })
        .catch(console.log);
    } else {
      Axios.get(Endpoints.version.GET)
        .then((data) => {
          this.setState({
            version: data.data,
          });
        })
        .catch(console.log);
    }
  }
  render() {
    if (this.state.version) {
      return (
        <footer>
          <p className="footerVersion" id="footerMeta">
            {this.state.version.version} &nbsp;|&nbsp;
            {this.state.version.region} |&nbsp;{" "}
            {capAll(this.state.version.stage)}
          </p>
        </footer>
      );
    }
    return (
      <footer className="footer">
        <p className="footerVersion" id="footerMeta">
          |
        </p>
      </footer>
    );
  }
}
