import React, { Component } from "react";
import { Fragment } from "react";

export default class SSOLogout extends Component {
  logout() {
    this.props.ssoLogout();
    return (
      <Fragment>
        <div className="innerContainerAutoSize">
          <h2 className="text-center">Clearing session.</h2>
        </div>
      </Fragment>
    );
  }
  render() {
    return this.logout();
  }
}
