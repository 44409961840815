import React, { Fragment, Component } from "react";

export default class Footer extends Component {
  state = {};
  componentDidMount() {}
  render() {
    return (
      <Fragment>
        <div className="innerContainerAutoSize">
          <h2>Sorry, the requested page cannot be found.</h2>
        </div>
      </Fragment>
    );
  }
}
