const moment = require("moment");

const capFirst = (string) => {
  if (typeof string !== "string") {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const capAll = (string) => {
  if (typeof string !== "string") {
    return string;
  }
  return string.toUpperCase();
};
const isoDateShort = (time) => {
  return moment(time).format("YYYY-MM-DD");
};
const zeroIfNull = (string) => {
  if (typeof string === "undefined") {
    return 0;
  } else return string;
};
const priceDollars = (int) => {
  if (typeof int !== "number") {
    return int;
  }
  return int.toFixed(2);
};
const priceCents = (int) => {
  if (typeof int !== "number") {
    return int;
  }
  int = int / 100;
  return int.toFixed(2);
};
const dollarsCentsSixDig = (int) => {
  if (typeof int !== "number") {
    return int;
  }
  int = int / 10000000000;
  return int.toFixed(6);
};
const isoDateTime = (value) => {
  const time = moment(value).format("YYYY-MM-DD H:mm:ss");
  return time;
};

export {
  capFirst,
  zeroIfNull,
  isoDateShort,
  priceCents,
  priceDollars,
  dollarsCentsSixDig,
  isoDateTime,
  capAll,
};
