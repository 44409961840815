import jwt_decode from "jwt-decode";
import Endpoints from "../endpoints.json";
import Axios from "axios";
import { sleep, idpURL } from "./helperFunctions";

class tokenManager {
  user;
  token;
  constructor(setUser) {
    this.setUserGlobal = setUser;
  }
  init() {
    this.token = localStorage.getItem("token");
    this.singleSignOut = localStorage.getItem("singleSignOut");
    if (typeof this.token === "string") {
      this.setToken(this.token);
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  }
  isLoggedIn() {
    return this.loggedIn;
  }
  getToken() {
    return this.token;
  }
  setUserByToken = async (token) => {
    this.user = jwt_decode(token);
    this.user.token = token;
    let date = Date.now();
    if (this.user.expireTime < date) {
      this.logoutExpire(); //If using expired token, logout triggers to clear session.
    } else {
      this.setUserGlobal(this.user);
      await this.tokenExpirationTimer(this.user);
    }
  };
  async tokenExpirationTimer(user) {
    this.loggedIn = true;
    let date = Date.now();
    let tokenExpiration = user.expireTime - date;
    let tokenTimerDuration = tokenExpiration * 0.9; //Refresh when 90% of tokens life is consumed/
    await sleep(tokenTimerDuration);
    this.renewToken();
  }
  clearSession() {
    localStorage.clear();
    this.token = null;
    this.loggedIn = false;
    this.setUserGlobal(null);
  }

  logout() {
    this.clearSession();
    window.location = "/";
  }
  logoutExpire() {
    this.clearSession();
    window.location = "/login";
  }
  async ssoLogout() {
    localStorage.clear();
    const idp = await idpURL("logout");
    window.location = idp;
  }

  async initSSOLogout() {
    this.clearSession();
    window.location = await idpURL("logoutInit");
  }

  setToken = (token) => {
    localStorage.setItem("token", token);
    this.setUserByToken(token);
    this.token = token;
    Axios.defaults.headers.common["Authorization"] = "Bearer: " + token;
  };
  async renewToken() {
    if (this.token) {
      Axios.get(Endpoints.renewSession.GET)
        .then(async (res) => {
          this.setToken(res.data.token);
        })
        .catch(() => {
          this.logout();
          window.location = "/login";
        });
    } else {
      this.logout();
    }
  }
  async refreshToken(redir, resetPage) {
    if (this.token) {
      if (typeof redir !== "string") {
        redir = window.location.pathname;
      }
      if (typeof resetPage !== "boolean") {
        resetPage = true;
      }
      Axios.get(Endpoints.refreshToken.GET)
        .then(async (res) => {
          this.setToken(res.data.token);
          if (resetPage === true) {
            window.location = redir;
          }
        })
        .catch(() => {
          //Expired token
          this.logout();
          window.location = "/login";
        });
    } else {
      this.logout();
    }
  }
}

export default tokenManager;
