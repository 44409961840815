import React, { Fragment, Component } from "react";
import Axios from "axios";
import Endpoints from "../endpoints.json";
import { name } from "../../package.json";

export default class Contact extends Component {
  state = { buttonDisabled: true, formValues: {} };

  evalSubmitablity = (fieldName, value) => {
    let formValues = this.state.formValues;
    if (fieldName) {
      if (!value) {
        delete formValues[fieldName];
      } else {
        formValues[fieldName] = value;
      }
    }
    const requiredFields = ["firstName", "lastName", "email", "comment"];
    let reqFieldLength = requiredFields.length;
    for (let i = 0; i < requiredFields.length; i++) {
      const requiredField = requiredFields[i];
      const formValue = formValues[requiredField];
      if (formValue && formValue.length >= 1) {
        reqFieldLength--;
      }
      if (reqFieldLength === 0) {
        this.setState({ buttonDisabled: false, formValues });
      } else {
        this.setState({ buttonDisabled: true, formValues });
      }
    }
  };

  post = (data) => {
    this.setState({ error: null });
    let config = {
      headers: {},
    };
    Axios.post(Endpoints.contactEmail.POST, data, config)
      .then((res) => {
        this.setState({
          formSubmitted: true,
          messageID: res.data.response,
          error: null,
        });
      })
      .catch((err) => {
        let error;
        console.log({ err });
        if (!err.response) {
          error = "Unable to reach server.";
        } else {
          error = err.response.data.errorMessage;
        }
        this.setState({ error, buttonDisabled: false });
      });
  };

  handleSubmit = (formData) => {
    formData.preventDefault();
    const data = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      comment: this.comment,
      initiatingAppName: name,
      contactTemplate: "default",
      additionalData: this.additionalData,
    };
    this.setState({ buttonDisabled: true });
    this.post(data);
  };
  submitForm = () => (
    <div className="innerContainerAutoSize">
      <h2 id="contact-submitHeader">Contact Us</h2>
      <h5 className="errorMessage">{this.state.error}</h5>
      <div className="contactForm">
        <form className="contactForm" onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label id="contact-labelFirstName">First Name</label>
            <input
              id="contact-inputFirstName"
              type="text"
              className="form-control"
              placeholder="First Name"
              onInput={(e) => {
                (this.firstName = e.target.value),
                  this.evalSubmitablity("firstName", e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label id="contact-labelLastName">Last Name</label>
            <input
              type="text"
              id="contact-inputLastName"
              className="form-control"
              placeholder="Last Name"
              onInput={(e) => {
                (this.lastName = e.target.value),
                  this.evalSubmitablity("lastName", e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label id="contact-labelEmail">Email</label>
            <input
              type="email"
              id="contact-inputEmail"
              className="form-control"
              placeholder="Email"
              onInput={(e) => {
                (this.email = e.target.value),
                  this.evalSubmitablity("email", e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label id="contact-labelComment">Comment</label>
            <input
              type="textarea"
              id="contact-inputComment"
              className="form-control"
              placeholder="Comment"
              onInput={(e) => {
                (this.comment = e.target.value),
                  this.evalSubmitablity("comment", e.target.value);
              }}
            />
          </div>
          <button
            id="contact-buttonSubmit"
            className="btn btn-primary btn-block"
            disabled={this.state.buttonDisabled}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
  render() {
    if (this.state.formSubmitted) {
      return (
        <div className="innerContainerAutoSize">
          <div>
            <h2>Contact Us</h2>
            <div>
              <p>Thank you!</p>
              <p>Message ID: </p>
              <p id="contact-messageID">{this.state.messageID}</p>
            </div>
          </div>
        </div>
      );
    }
    return <Fragment>{this.submitForm()}</Fragment>;
  }
}
