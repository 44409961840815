import React, { Component } from "react";
import { Fragment } from "react";
import { idpURL } from "../../lib/helperFunctions";

export default class ssoLoginReferrer extends Component {
  async componentDidMount() {
    const redirURL = await idpURL("login");

    window.location = redirURL;
  }
  render() {
    return (
      <Fragment>
        {" "}
        <div className="innerContainerAutoSize">
          <h4>Redirect to auth.</h4>
          <img
            src="/images/spinner/spin1.gif"
            width="50"
            height="50"
            className="center"
          />
        </div>
      </Fragment>
    );
  }
}
