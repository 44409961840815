import React, { Component } from "react";

export default class Home extends Component {
  render() {
    if (this.props.user) {
      return (
        <div className="innerContainerAutoSize">
          <h2 id="home-header">
            Hello {this.props.user.firstName} {this.props.user.lastName}
          </h2>
        </div>
      );
    }
    return (
      <div className="innerContainerAutoSize">
        <h2 id="home-headerNotLoggedIn">You are not logged in.</h2>
      </div>
    );
  }
}
